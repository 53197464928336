<!-- 平台公告 -->
<template>
    <div class="announcement">
        <header>
            平台公告
        </header>
        <el-timeline>
            <el-timeline-item v-for="(item, index) in list" :key="index" :timestamp="item.art_addtime" placement="top">
                <el-card>
                    <h4>{{ item.art_title }}</h4>
                    <p>{{ item.art_content }} </p>
                </el-card>
            </el-timeline-item>

        </el-timeline>
        <notLoginTips></notLoginTips>
    </div>
</template>

<script>

import notLoginTips from '../../components/notLoginTips.vue';
export default {
    data() {
        return {
            list: []
        };
    },

    components: { notLoginTips },
    filters: {
        ts2Date: (timestamp) => {
            if (timestamp == 0) {
                return "";
            }
            let date = new Date(timestamp * 1000), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
                Y = date.getFullYear() + "-",
                M =
                    (date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1) + "-",
                D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ",
                h =
                    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
                    ":",
                m =
                    (date.getMinutes() < 10
                        ? "0" + date.getMinutes()
                        : date.getMinutes()) + ":",
                s =
                    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },
    },

    mounted() {
        this.getList()
    },

    methods: {
        async getList() {

            let res = await this.$axios
                .get("/api/Web/getArticleList", {
                    params: {
                        arc_code: "notice",
                        limit: 20
                    },
                })

            this.list = res.data.rows
        }
    }
}

</script>
<style lang="scss" scoped>
.announcement {
    margin: 40px 50px 50px 100px;
    width: 70%;
    min-height: 500px;

    header {
        margin-bottom: 20px;
        font-size: 20px;

    }
}
</style>